import { ICommentThread, ICommentType } from "@shared/types/CommentThread";
import { IObjectId } from "@shared/types/lib";
import { createHttpRequest } from "./lib/createHttpRequest";

/**
 * Returns an array of ids of comment threads for a project, paginated by skip and limit if provided.
 */
export const getCommentsByProjectId = createHttpRequest<
  { projectId: string; page: number; pageSize: number; type?: ICommentType },
  IObjectId[]
>({
  method: "get",
  getUrl({ projectId }) {
    return `/comments/project/${projectId}`;
  },
  getConfig({ page, pageSize, type }) {
    return {
      params: { skip: page * pageSize, limit: pageSize, type },
    };
  },
});

export const getCommentsByCommentIds = createHttpRequest<
  { commentIds: string[]; projectId?: string; type?: ICommentType },
  ICommentThread[]
>({
  method: "get",
  getUrl() {
    return `/comments`;
  },
  getConfig({ commentIds, projectId, type }) {
    return {
      params: { commentIds, projectId, type },
    };
  },
});

export const createCommentThread = createHttpRequest<
  {
    projectId: string;
    projectName: string;
    commentText: string;
    mentionedUserIds: string[];
    textItemId: string;
    from: string;
    isSuggestion?: boolean;
  },
  ICommentThread
>({
  method: "post",
  getUrl() {
    return `/comments/postCompThread`;
  },
  getConfig({ projectId, projectName, commentText, mentionedUserIds, textItemId, from, isSuggestion }) {
    return {
      data: {
        doc_id: projectId,
        doc_name: projectName,
        first_comment: commentText,
        figma_node_id: null,
        mentionedUserIds,
        comp_id: textItemId,
        from,
        ws_comp_id: null,
        suggestion: isSuggestion,
      },
    };
  },
});

export const postToThread = createHttpRequest<
  { commentThreadId: string; text: string; projectName: string; mentionedUserIds: string[]; from: string },
  ICommentThread
>({
  method: "post",
  getUrl({ commentThreadId }) {
    return `/comments/postToThread/${commentThreadId}`;
  },
  getConfig({ text, projectName, mentionedUserIds, from }) {
    return {
      data: { text, doc_name: projectName, mentionedUserIds, from },
    };
  },
});

export const resolveThread = createHttpRequest<
  { commentThreadId: string; is_resolved: boolean; projectName: string; suggestion_accepted?: boolean; from: string },
  ICommentThread
>({
  method: "post",
  getUrl({ commentThreadId }) {
    return `/comments/resolveThread/${commentThreadId}`;
  },
  getConfig({ is_resolved, projectName, suggestion_accepted, from }) {
    return {
      data: { is_resolved, doc_name: projectName, suggestion_accepted, from },
    };
  },
});
