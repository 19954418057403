import { userAtom } from "@/stores/Auth";
import { projectFigmaFileLinkAtom } from "@/stores/Project";
import { filteredFramePreviewsAtom, previewsJobStatusAtom } from "@/stores/ProjectDesignPreviews";
import { onTextItemClickActionAtomFamily, selectedTextItemIdsAtom } from "@/stores/ProjectSelection";
import Badge from "@ds/atoms/Badge";
import Icon from "@ds/atoms/Icon";
import LoadingSpinner from "@ds/atoms/LoadingSpinner";
import SeparatorDot from "@ds/atoms/SeparatorDot";
import Text from "@ds/atoms/Text";
import Callout from "@ds/molecules/Callout";
import ChangeIndicator from "@ds/molecules/ChangeIndicator";
import Scrollbar from "@ds/molecules/Scrollbar";
import Tooltip from "@ds/molecules/Tooltip";
import Autorenew from "@mui/icons-material/Autorenew";
import { IFramePreviewDataWithSelectionData, ITextNodeWithSelectionData } from "@shared/types/DittoProject";
import logger from "@shared/utils/logger";
import { getNoSecondsFormatter } from "@shared/utils/timeAgoFormatters";
import classNames from "classnames";
import { getDefaultStore, useAtomValue, useSetAtom } from "jotai";
import React, { useEffect, useMemo, useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";
import ReactTimeago from "react-timeago";
import style from "./style.module.css";

interface DesignPreviewsProps {
  className?: string;
}

const HIGHLIGHT_BOX_PADDING = 5;

const DesignPreviews = (props: DesignPreviewsProps) => {
  const previews = useAtomValue(filteredFramePreviewsAtom);
  const selectedTextItemIds = useAtomValue(selectedTextItemIdsAtom);
  const user = useAtomValue(userAtom, { store: getDefaultStore() });

  return (
    <div className={classNames(style.designPreviewsContainer, props.className)}>
      {user?.isFigmaAuthenticated === false && (
        <div className={style.figmaNotConnected}>
          <Callout
            variant="secondary"
            header="You're not connected to Figma"
            content={
              <Text size="micro" inline>
                You won't be able to fetch updated design previews until you{" "}
                <Text
                  size="micro"
                  asLink
                  color="action"
                  onClick={() => window.open(`${window.location.origin}/account/user?reauthorize_figma=true`, "_blank")}
                >
                  connect your Figma account.
                </Text>
              </Text>
            }
          ></Callout>
        </div>
      )}

      <Scrollbar className={style.scrollWrapper}>
        <div className={style.previews}>
          {previews.length === 0 && (
            <div className={style.emptyState}>
              <Text color="secondary">
                {selectedTextItemIds.length === 0
                  ? "No design previews available"
                  : "No design previews available for selected text items"}
              </Text>
            </div>
          )}
          {previews.map((preview) => (
            <DesignPreview
              key={preview.frameNodeId}
              frameNodeId={preview.frameNodeId}
              previewUrl={preview.previewUrl}
              lastUpdated={preview.lastUpdated}
              textNodesToHighlight={preview.textNodesToHighlight}
              position={preview.position}
              frameName={preview.frameName}
              frameIsModified={preview.frameIsModified}
            />
          ))}
        </div>
      </Scrollbar>
    </div>
  );
};

DesignPreviews.Fallback = () => {
  return (
    <div className={style.designPreviewsContainer}>
      <div className={style.previews}>
        <Skeleton height={400} className={style.previewSkeleton} />
        <Skeleton height={400} className={style.previewSkeleton} />
        <Skeleton height={400} className={style.previewSkeleton} />
      </div>
    </div>
  );
};

interface Position {
  x: number;
  y: number;
  width: number;
  height: number;
}

interface DesignPreviewProps extends IFramePreviewDataWithSelectionData {}

const CONTAINER_PADDING = 16;

function DesignPreview(props: DesignPreviewProps) {
  const figmaFileLink = useAtomValue(projectFigmaFileLinkAtom);
  const previewJobStatus = useAtomValue(previewsJobStatusAtom);
  const waitingForPreviewUpdates = previewJobStatus === "active";
  // TODO: we'll expand the loading/error states in a later ticket
  // [DIT-8475: Loading & error states for preview images](https://linear.app/dittowords/issue/DIT-8475/loading-and-error-states-for-preview-images)
  const [imageLoaded, setImageLoaded] = useState(false);
  const [imgError, setImgError] = useState<{ exists: boolean; status: string | number | null }>({
    exists: false,
    status: null,
  });
  const [imageRenderDimensions, setImageRenderDimensions] = useState<Position | null>(null);
  const [imageContainerDimensions, setImageContainerDimensions] = useState<Position | null>(null);
  const imgRef = useRef<HTMLImageElement>(null);
  const imageContainerRef = useRef<HTMLDivElement>(null);

  const imageMaxWidth = useMemo(() => {
    if (!imageContainerDimensions) return null;
    const containerWidth = imageContainerDimensions.width - CONTAINER_PADDING * 2;

    return Math.min(containerWidth, props.position.width);
  }, [imageContainerDimensions, props.position.width]);

  const frameLink = useMemo(() => {
    if (!figmaFileLink) return null;
    return `${figmaFileLink}?node-id=${props.frameNodeId.replace(":", "-")}&node-type=frame`;
  }, [figmaFileLink, props.frameNodeId]);

  function handleImgLoaded() {
    updateImageRenderDimensions();
    setImageLoaded(true);
    setImgError({ exists: false, status: null });
  }

  const handleImgLoadingError = async (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    e.persist();
    if (!props.previewUrl) return;
    try {
      const imgResponse = await fetch(props.previewUrl);
      if (!imgResponse.ok) {
        setImgError({ exists: true, status: imgResponse.status });
      } else {
        setImgError({ exists: true, status: null });
      }
    } catch (e) {
      logger.error("Failed to fetch image preview", { context: { url: props.previewUrl } }, e);
      setImgError({ exists: true, status: null });
    }
  };

  function updateImageRenderDimensions() {
    if (imgRef.current) {
      setImageRenderDimensions({
        x: imgRef.current.offsetLeft,
        y: imgRef.current.offsetTop,
        width: imgRef.current.offsetWidth,
        height: imgRef.current.offsetHeight,
      });
    }
  }

  function updateImageContainerDimensions() {
    if (imageContainerRef.current) {
      setImageContainerDimensions({
        x: imageContainerRef.current.offsetLeft,
        y: imageContainerRef.current.offsetTop,
        width: imageContainerRef.current.offsetWidth,
        height: imageContainerRef.current.offsetHeight,
      });
    }
  }

  useEffect(function addResizeListener() {
    function handleResize() {
      updateImageRenderDimensions();
      updateImageContainerDimensions();
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(
    function updateImageRenderDimensionsOnMount() {
      if (imgRef.current?.offsetWidth && imgRef.current?.offsetHeight) updateImageRenderDimensions();
    },
    [imgRef.current?.offsetWidth, imgRef.current?.offsetHeight]
  );

  useEffect(
    function loadImage() {
      if (!props.previewUrl || !imgRef.current) return;

      if (imgRef.current?.src !== props.previewUrl) setImageLoaded(false);

      const downloadingImage = new Image();

      downloadingImage.onload = function (this: HTMLImageElement) {
        if (imgRef.current) {
          imgRef.current.src = this.src;
        }

        setImageLoaded(true);
        setImgError({ exists: false, status: null });
      };

      downloadingImage.src = props.previewUrl;
    },
    [props.previewUrl]
  );

  const scaledTextNodeHighlights: ITextNodeWithSelectionData[] = useMemo(() => {
    if (!imageRenderDimensions) return [];

    const scaleFactor = imageRenderDimensions.width / props.position.width;

    return props.textNodesToHighlight.map((textNode) => {
      const { x, y, width, height } = getTextNodeRelativePosition(props.position, textNode.position);
      const scaledX = x * scaleFactor - HIGHLIGHT_BOX_PADDING;
      const scaledY = y * scaleFactor - HIGHLIGHT_BOX_PADDING;
      const scaledWidth = width * scaleFactor + HIGHLIGHT_BOX_PADDING * 2;
      const scaledHeight = height * scaleFactor + HIGHLIGHT_BOX_PADDING * 2;

      return {
        ...textNode,
        position: {
          x: scaledX,
          y: scaledY,
          width: scaledWidth,
          height: scaledHeight,
        },
      };
    });
  }, [imageRenderDimensions, props.position, props.textNodesToHighlight]);

  return (
    <div className={style.preview}>
      <div className={style.header}>
        <div className={style.nameAndBadge}>
          {props.frameIsModified && (
            <Tooltip
              type="invert"
              size="sm"
              content="This frame contains text which has been modified in Ditto, but not yet synced to Figma."
              textAlign="center"
            >
              <Badge size="sm" color="blue-inverted" style={{ cursor: "default" }}>
                Modified
              </Badge>
            </Tooltip>
          )}
          <Text size="small" color="primary" weight="medium">
            {props.frameName}
          </Text>
        </div>

        <div className={style.details}>
          {waitingForPreviewUpdates ? (
            <Tooltip type="invert" size="sm" content="Re-fetching preview image in the background">
              <div className={style.fetchingPreview}>
                <Icon className={style.spinningIcon} size="xxs" Icon={<Autorenew />} />
                <SeparatorDot style={{ backgroundColor: "#d9d9d9" }} />
                <Text size="small" color="tertiary" weight="light">
                  Fetching preview image
                </Text>
              </div>
            </Tooltip>
          ) : (
            <>
              <Text inline size="small" color="tertiary" weight="light">
                Fetched{" "}
                <ReactTimeago
                  date={props.lastUpdated}
                  minPeriod={30}
                  formatter={getNoSecondsFormatter("less than a minute ago")}
                />
              </Text>
            </>
          )}
          <SeparatorDot style={{ backgroundColor: "#d9d9d9" }} />
          <Text
            asLink
            size="small"
            color="tertiary"
            weight="medium"
            onClick={() => frameLink && window.open(frameLink, "_blank")}
          >
            Open in Figma
          </Text>
        </div>
      </div>

      <div className={style.imageContainer} ref={imageContainerRef}>
        {/* TODO: improve along with error state ticket! */}
        {/* {(!imageLoaded || (imgError.exists && waitingForPreviewUpdates)) && ( */}
        {!imageLoaded && (
          <div className={style.previewLoading}>
            <LoadingSpinner />
            <span>Loading image preview..</span>
          </div>
        )}
        <div className={style.imageWrapper}>
          <div className={style.overlay}>
            {scaledTextNodeHighlights.map((highlight) => (
              <TextNodeHighlight key={highlight.nodeId} highlight={highlight} />
            ))}
          </div>
          <img
            ref={imgRef}
            loading="lazy"
            className={style.image}
            onLoad={handleImgLoaded}
            onError={handleImgLoadingError}
            src={props.previewUrl}
            alt="preview"
            style={{ maxWidth: `${imageMaxWidth}px` }}
          />
        </div>
      </div>
    </div>
  );
}

function TextNodeHighlight(props: { highlight: ITextNodeWithSelectionData }) {
  const { highlight } = props;
  const onTextItemClick = useSetAtom(onTextItemClickActionAtomFamily(highlight.pluginData?.textItemId ?? ""));

  function handleHighlightClick(e: React.MouseEvent<HTMLDivElement>) {
    if (!highlight.pluginData?.textItemId) return;
    onTextItemClick({ richText: highlight.richText, e, skipInlineEdit: true });
  }

  return (
    <div
      className={classNames(style.highlight, highlight.isSelected && style.selected)}
      onClick={handleHighlightClick}
      style={{
        left: highlight.position.x,
        top: highlight.position.y,
        width: highlight.position.width,
        height: highlight.position.height,
      }}
    >
      {highlight.diff && (
        <ChangeIndicator
          className={style.changeIndicator}
          textBefore={highlight.diff.textBefore}
          textAfter={highlight.diff.textAfter}
          changeTime={highlight.diff.textItemUpdatedAt}
        />
      )}
    </div>
  );
}

// We store both the frame and text node positions as *absolute* positions -- this function converts
// a text node's position to a position relative to the frame.
function getTextNodeRelativePosition(framePos: Position, textNodePos: Position) {
  return {
    x: textNodePos.x - framePos.x,
    y: textNodePos.y - framePos.y,
    width: textNodePos.width,
    height: textNodePos.height,
  };
}

export default DesignPreviews;
